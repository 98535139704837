import { useMemo } from 'react';

import { getItemLocation } from 'Helpers/item';
import { isEmpty } from 'Helpers/objects';
import { getItemUrl, getItemUrlMobbi } from 'Helpers/url';
import { INSPECTION_INFO_STATUS } from 'Constants/items';
import { arrayToObject } from 'Helpers/array';
import { relativeDate } from 'Helpers/dates';

export const useItemInfo = (item, config) => {
    const {
        id,
        ad_id,
        name,
        title,
        user_id,
        category_id,
        spell,
        inspection_info = {},
        monetizationInfo,
        scoreFactor,
        user_type,
        'package': appliedPackage,
        display_date,
        created_at_first,
        created_at,
        isSpinViewAvailable,
        visible_campaigns,
        is_video_generated,
        video_url
    } = item;
    const mappedParameters = useMemo(() => {
        const result = {};
        const paramObj = arrayToObject(item.parameters || [], 'key');

        for (const key in paramObj) {
            const { formatted_value } = paramObj[key] || {};

            result[key] = formatted_value;
        }
        return result;
    }, [item]);

    const { id: spell_id = 0, key: spell_key = '', version: spell_v = '' } = spell || {};
    const slug = item && config && typeof config.get === 'function' && config.get('isOlxMobbi')
        ? getItemUrlMobbi(ad_id || id, title)
        : getItemUrl(ad_id || id, title);
    const image = item && item.images && item.images[0] || {};
    const price = item && item.price && item.price.value ? item.price.value.display : '';
    const monthly_installment = item && item.price && item.price.value && item.price.value.emi && item.price.value.emi.monthly_installment && item.price.value.emi.monthly_installment.raw ? item.price.value.emi.monthly_installment.raw : 0;
    const rawPriceValue = item && item.price && item.price.value ? item.price.value.raw : 0;
    const hasPromotion = item && item.has_promotion;
    const discountedPrice = item && item?.has_promotion && item?.price?.value?.discounted_price ? item.price.value.discounted_price.display : 0; // this field price item after discount
    const discountAmount = item && item?.has_promotion && item?.price?.value?.discount_amount ? item.price.value.discount_amount.raw : 0;
    const discountTag = item?.price?.value?.discount_tag;
    const priceCurrency = useMemo(() => {
        if (item && item.price && item.price.value && item.price.value.currency) {
            return {
                pre: item.price.value.currency.pre,
                post: item.price.value.currency.post
            };
        }

        return {};
    }, [item]);

    const isInspected = inspection_info && inspection_info.inspection_id
            && (!inspection_info.consent || inspection_info.consent === INSPECTION_INFO_STATUS.APPROVED);
    const itemLocation = useMemo(() => getItemLocation(item, 1), [item]);
    const isFeatured = !!((appliedPackage && (appliedPackage.id || appliedPackage.name))
        || (monetizationInfo && !isEmpty(monetizationInfo) && monetizationInfo.currentProduct));
    const displayTitle = useMemo(() => {
        const { year, m_year, make, model, m_tipe } = mappedParameters;

        return `${year || m_year}, ${make} ${model || m_tipe}`;
    }, [mappedParameters]);

    const { km_driven, m_year, make, year, model, mileage, m_tipe } = mappedParameters;
    const displayDate = relativeDate(
        display_date || created_at_first || created_at,
        undefined,
        { suppressHydrationWarning: true }
    );

    const displayDetails = useMemo(() => {
        const { fuel, m_fuel, km_driven, mileage, petrol } = mappedParameters;
        const displayDetailArr = [];

        if (fuel || m_fuel || petrol) {
            displayDetailArr.push(fuel || m_fuel || petrol);
        }
        if (km_driven || mileage) {
            displayDetailArr.push(km_driven || mileage);
        }
        if (itemLocation) {
            displayDetailArr.push(itemLocation);
        }

        return displayDetailArr;
    }, [mappedParameters, itemLocation]);

    const subtitle = `${m_year || year || ''} ${km_driven || mileage ? '-' : ''} ${km_driven || mileage || ''}`;

    const subDetails = {
        displayDate,
        itemLocation
    };

    const visibleCampaignTagIds = visible_campaigns?.map(tag => tag.id) || [];

    return {
        category_id,
        displayTitle,
        displayDetails,
        id,
        image,
        isFeatured,
        isInspected,
        name,
        price,
        monthly_installment,
        rawPriceValue,
        priceCurrency,
        scoreFactor,
        slug,
        spell_id,
        spell_key,
        spell_v,
        title,
        user_id,
        user_type,
        subtitle,
        subDetails,
        make,
        model: model || m_tipe,
        year: m_year || year,
        isSpinViewAvailable,
        visibleCampaignTagIds,
        hasPromotion,
        discountedPrice,
        discountAmount,
        is_video_generated,
        video_url,
        discountTag
    };
};

export default useItemInfo;
